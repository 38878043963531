import React, { useState, useRef, useEffect, useContext } from 'react';

const Close = require("../../../../images/assets/close.png");


const FilterScoreDropdown = ({ onClose, onApply, initialState }) => {

    const [selection, setSelection] = useState(initialState.selection || null); // "consumer" or "xscore" or null
    const [consumerScore, setConsumerScore] = useState(initialState.consumerScore || { from: null, to: null });
    const [xScore, setXScore] = useState(initialState.xScore || { from: null, to: null });
    const [validationMessage, setValidationMessage] = useState('');
    const [isApplyVisible, setApplyVisible] = useState(false);

    useEffect(() => {
        setSelection(initialState.selection || null);
        setConsumerScore(initialState.consumerScore || { from: null, to: null });
        setXScore(initialState.xScore || { from: null, to: null });
        setValidationMessage('');
        setApplyVisible(initialState.selection !== null ? true : false);
    }, [initialState]);

    const [fields, setFields] = useState({
        consumer: { from: null, to: null },
        xscore: { from: null, to: null },
        selectedType: null, // 'consumer' or 'xscore'
        validationMessage: '',
        applyVisible: false,
    });

    // Reset all fields
    const resetFilters = () => {
        setSelection(null);
        setConsumerScore({ from: null, to: null });
        setXScore({ from: null, to: null });
        setValidationMessage('');
        setApplyVisible(false);
        setConsumerScore((prev) => ({ ...prev, ['from']: '', ['to']: '' }));
        setXScore((prev) => ({ ...prev, ['from']: '', ['to']: '' }));
    };

    // Handle changes in the score fields
    const handleInputChange = (e, type, field) => {
        const value = e.target.value;
        if (type === 'consumer') {
            setConsumerScore((prev) => ({ ...prev, [field]: value }));
            validateInputs({ ...consumerScore, [field]: value }, xScore, 'consumer');
        } else if (type === 'xscore') {
            setXScore((prev) => ({ ...prev, [field]: value }));
            validateInputs(consumerScore, { ...xScore, [field]: value }, 'xscore');
        }
    };

    // Validate inputs and update the state accordingly
    const validateInputs = (consumer, xscore, currentType) => {
        let updatedFields = { ...fields };
        let message = '';
        let applyVisible = false;

        // Consumer Score validation
        if (currentType === 'consumer' && selection === 'consumer') {
            const { from, to } = consumer;
            if (from && to) {
                if (from > 900 || to < 300) {
                    message = 'Performance Consumer Score: "From" cannot exceed 900 and "To" cannot be less than 300.';
                } else if (from < 300 || to > 900) {
                    message = 'Performance Consumer Score range should be between 300 and 900.';
                } else {
                    applyVisible = true;
                }
            }

            updatedFields = {
                consumer: { from, to },
                xscore: { from: null, to: null },
                selectedType: 'consumer',
            };
        }

        // X Score validation
        if (currentType === 'xscore' && selection === 'xscore') {
            const { from, to } = xscore;
            if (from && to) {
                if (from > 2000 || to < 1000) {
                    message = 'X Sell Score: "From" cannot exceed 2000 and "To" cannot be less than 1000.';
                } else if (from < 1000 || to > 2000) {
                    message = 'X Sell Score range should be between 1000 and 2000.';
                } else {
                    applyVisible = true;
                }
            }

            updatedFields = {
                consumer: { from: null, to: null },
                xscore: { from, to },
                selectedType: 'xscore',
            };
        }

        // Ensure one selection is made
        if (!selection) {
            message = 'Please select either Performance Consumer Score or X Sell Score.';
            applyVisible = false;
        }

        setValidationMessage(message);
        setApplyVisible(applyVisible);
    };

    // Handle selection
    const handleSelection = (type) => {
        setSelection(type);
        setValidationMessage(''); // Clear any previous validation message
        setApplyVisible(false); // Hide apply button until valid inputs are provided
    };

    const handleApply = () => {
        if (!selection) {
            setValidationMessage('Please select either Performance Consumer Score or X Sell Score.');
            return;
        }

        const appliedData = {
            selectedType: selection,
            consumer: selection === 'consumer' ? consumerScore : { from: null, to: null },
            xscore: selection === 'xscore' ? xScore : { from: null, to: null },
        };


        onApply(appliedData);
        onClose();
        //setAppliedFilterData(appliedData);
        // alert(`Applied Data: ${JSON.stringify(appliedData)}`);
    };

    return (
        <div className="filter-dropdown">
            <div className='filter-header m-0 py-3 px-3'>
                <p className="p-0 m-0" style={{ color: '#212121' }}>Score Filters</p>
                <img src={Close} className="close-btn" onClick={onClose} />
            </div>
            <div className='divider p-0 m-0'></div>
            <div className="filter-content p-0 px-3">
                {/* Filters */}
                <div className='p-0 m-0'>
                    <label className='p-0 m-0 d-flex flex-row align-items-center'>
                        <input type="radio" name="scoreType"
                            checked={selection === 'consumer'}
                            onChange={() => handleSelection('consumer')}
                        />
                        <p className='p-0 m-0 score-text ps-1 pt-1'>Perform Consumer 2.0 Score</p>
                    </label>
                    <div className="score-range m-0">
                        <input type="number"
                            value={consumerScore.from || ''}
                            onChange={(e) => handleInputChange(e, 'consumer', 'from')}
                            disabled={selection !== 'consumer'}
                            placeholder="300" className='input-box form-control' />

                        <span>→</span>

                        <input
                            value={consumerScore.to || ''}
                            onChange={(e) => handleInputChange(e, 'consumer', 'to')}
                            disabled={selection !== 'consumer'}
                            type="number" placeholder="900" className='input-box form-control' />
                    </div>
                </div>
                <div className='p-0 m-0 d-flex flex-row justify-content-center align-items-center mt-2'>
                    <div className='divider'></div>
                    <p className='p-0 m-0 px-2'>or</p>
                    <div className='divider'></div>
                </div>
                <div className='p-0 m-0'>
                    <label className='p-0 m-0 d-flex flex-row align-items-center'>
                        <input
                            checked={selection === 'xscore'}
                            onChange={() => handleSelection('xscore')}
                            type="radio" name="scoreType" />
                        <p className='p-0 m-0 score-text ps-1 pt-1'>X Sell Score</p>
                    </label>
                    <div className="score-range m-0">
                        <input
                            value={xScore.from || ''}
                            disabled={selection !== 'xscore'}
                            onChange={(e) => handleInputChange(e, 'xscore', 'from')}
                            type="number" placeholder="From" className='input-box form-control' />
                        <span>→</span>
                        <input
                            value={xScore.to || ''}
                            onChange={(e) => handleInputChange(e, 'xscore', 'to')}
                            disabled={selection !== 'xscore'}
                            type="number" placeholder="To" className='input-box form-control' />
                    </div>

                </div>
                <div className='my-2'>
                    {validationMessage && <p className="m-0 p-0 note text-start">{validationMessage}</p>}
                </div>
                {/* Buttons */}
                <div className='mt-3'>
                    <div className="filter-actions m-0 p-0">
                        <button type='button' onClick={resetFilters} className="reset-btn m-0">Reset</button>
                        <button type='button' onClick={handleApply} disabled={!isApplyVisible} className="apply-btn m-0">Apply</button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default FilterScoreDropdown;
