import React, { useState, useRef, useEffect } from 'react';
import '../filter-score-dropdown/filter-score-dropdown.css'
import FilterScoreDropdown from './filter-score-dropdown.js';

const FilterIC = require("../../../../images/assets/filter.png");

const FilterIconWithDropdown = ({ onApply, isClearFilter, filterEnableDisable, initialState }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggleDropdown = () => {
        if (filterEnableDisable.isScoreEnable) {
            setDropdownOpen(!isDropdownOpen)
        }

    };

    const responseFromScoreDropdown = (data) => {
        onApply(data);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="filter-container" ref={dropdownRef}>
            <div className="filter-parent" style={{ opacity: filterEnableDisable.isScoreEnable ? 1.0 : 0.8 }} onClick={handleToggleDropdown}>
                <img src={FilterIC} alt="Filter Icon" className="filter-icon" />
                {isClearFilter && filterEnableDisable.isScoreEnable && <div className="indicator"></div>}
            </div>
            {isDropdownOpen && <FilterScoreDropdown
                initialState={initialState}
                onApply={(data) => responseFromScoreDropdown(data)}
                onClose={() => setDropdownOpen(false)} />}
        </div>
    );
};

export default FilterIconWithDropdown;
