import React, { useEffect, useState } from 'react';
import './boost-tab-component.css';
import '../../../App.css';
import { flushSync } from "react-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import getUserData from '../../../Common/helper';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer';
import AliceCarousel from 'react-alice-carousel';
// import { authCode, emailVerification, emailParseData } from '../../../redux/actions/auth_action';
import ErrorMessage from '../error-message/error-message';
import ScoreDetails from '../Header-Page/components/score-details/score-range-dailog';
import { ReactDialogBox } from 'react-js-dialog-box';
import ReactApexChart from "react-apexcharts";
import BoostInsights from '../Header-Page/components/insihght/boost-insights-dilog';
import Select, { components } from 'react-select';
import { customStyles } from '../../Dashboard/Header-Page/custom-style-component-bureau';

//Graph Component Defined 
import TranscationAnalysis from '../bank-tab-component/Component/Graph/transcation-analysis';
import TopCategory from '../bank-tab-component/Component/Graph/top-category';
import TopMerchant from '../bank-tab-component/Component/Graph/top-merchant';
import CreditCard from '../bank-tab-component/Component/Graph/credit-card-util';
import TopInvestment from '../bank-tab-component/Component/Graph/top-investment';
import MonthlyObligation from '../bank-tab-component/Component/Graph/monthaly_obligation';
import GetBankLogo from '../bank-tab-component/Component/Graph/get-bank-logo';
import { boostScoreReportBID } from '../../../redux/actions/b2b_action';
import { boostScoreReportProdBID } from '../../../redux/actions/prod-b2b_action';

const Score = require("../../../images/assets/BoostScore.png");
const Expense = require("../../../images/assets/income_expense.png");
const Obligation = require("../../../images/assets/income_obligation.png");
const Investments = require("../../../images/assets/income_investments.png");
const Insurance = require("../../../images/assets/income_insurance.png");
const NoFoundDocs = require("../../../images/assets/no_saving_thumb.png");
const BankLogo = require("../../../images/Bank/Bank.png");
const SpendingLogo = require("../../../images/assets/top_spending.png");
const MerchantLogo = require("../../../images/assets/top_merchant.png");
const CreditCardLogo = require("../../../images/assets/credit_card.png");
const InvestmentLogo = require("../../../images/assets/top_investment.png");
const ObligationLogo = require("../../../images/assets/top_obligation.png");
const ConsentLogo = require("../../../images/assets/consent_logo.png");

const BoostTabComponent = (props) => {

  const [boostScoreReportData, setBoostScoreReportData] = useState();
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [userExists, setUserExists] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [dialogHeight, setDialogHeight] = useState('auto');
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [message, setMessage] = useState();
  const [analysisReportData, setAnalysisReportData] = useState();
  const [incomeVsExpenseData, setIncomeVsExpenseData] = useState();
  const [topSpendingCategoryData, setTopSpendingCategoryData] = useState();
  const [topMerchantData, setTopMerchantData] = useState();
  const [topCardUtilizationData, setTopCardUtilizationData] = useState();
  const [topMonthlyObligationData, setMonthlyObligationData] = useState();
  const [selectedAnalysisData, setSelectedAnalysisData] = useState();
  const [insightsDialog, setInsightsDialog] = useState(false);
  const [investmentData, setInvestmentData] = useState();
  const [getRefreshTokenStatus, setRefreshTokenData] = useState();
  const { state } = useLocation();
  const [insightsItem, setInsightsItem] = useState([])
  const [bankList, setBankList] = useState([])
  const [acHolderName, setAcHolderName] = useState()
  const [transactionPeriod, setTransactionPeriod] = useState()
  const [newBankName, setNewBankname] = useState();
  const [bankIndexCurrent, setBankIndexCurrent] = useState(0)

  let bankIndex = 0;
  let bankNameList = [];
  useEffect(() => {
    if (!user?.access_token) {
      navigate('/');
    } else {

      const data = getUserData()
      setUserData(data)
      let param = {
        "businessUserId": props.businessUserId
      }
      getBoostScoreReport(0, param);
      //getRefreshTokenData();
    }
  }, [])





  const { user } = useSelector(state => {
    return state.authReducer;
  });

  const openInsights = (data, boostData) => {
    setSelectedAnalysisData(data);
    setInsightsDialog(true)
    setDialogHeight('auto')
    // window.dispatchEvent(new Event('resize'))
  }

  const openScoreDetails = (name) => {
    setScoreDitailsDialog(true)
    setDialogHeight('auto')
  }

  const closeinsght = () => {
    setInsightsDialog(false)
    setDialogHeight('auto')
    window.dispatchEvent(new Event('resize'))
  }

  const closeDialog = () => {
    setIsOpenDialog(false)
    setDialogHeight('auto')
  }

  // const getRefreshTokenData = () => {
  //   refreshToken().then(resp => {
  //     setRefreshTokenData(resp);
  //   }).catch(error => {
  //     setRefreshTokenData(error);
  //   })
  // }

  const insightsAnalysisReports = (analysisReportData) => {
    var i = [];
    analysisReportData?.ratio?.forEach(boostData => {
      i.push(
        <div className="col insights-main-box-height" key={boostData.status}>
          <div className='insights-sub-main-box h-100'>
            <div className="cards h-100">
              <div className="card-bod px-2 pt-3 pb-2">
                <div className='insights-imag-box mb-2'>
                  {(boostData && boostData.label === 'Income to Expense') ? <img src={Expense} className='img-fluid pb-1' alt="expense" /> : ""}
                  {(boostData && boostData.label === 'Income to Investments') ? <img src={Investments} className='img-fluid pb-1' alt="investments" /> : ""}
                  {(boostData && boostData.label === 'Income to Obligations') ? <img src={Obligation} className='img-fluid pb-1' alt="obligation" /> : ""}
                  {(boostData && boostData.label === 'Income to Insurance') ? <img src={Insurance} className='img-fluid pb-1' alt="insurance" /> : ""}
                </div>
                <h5 className="card-title insights-box-main-title p-0 m-0 truncate">{(boostData && boostData !== null) ? (boostData?.label && boostData?.label != null) ? boostData.label : '' : ''}</h5>
              </div>
              <div className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights(boostData, analysisReportData) }}>
                <p className="card-text insights-card-main-text p-0 m-0">{(boostData && boostData !== null) ? (boostData.status && boostData.status !== null) ? boostData?.status : '' : ''}</p>
                <div className='d-flex justify-content-between align-items-center mt-1'>
                  <h5 className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(boostData && boostData !== null) ? (boostData?.ratio && boostData?.ratio !== null) ? (boostData?.ratio) : '' : ''}</h5>
                  <i className="bi bi-chevron-right right-ariw-icons"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    setInsightsItem(i)
  }


  const Control = ({ children, ...props }) => {
    const [inputValue, setInputValue] = useState('');
    const { selectProps, hasValue } = props;

    return (
      <components.Control {...props}>
        <div style={{ position: 'relative', display: 'flex', }}>
          {hasValue || inputValue ? (
            <label
              style={{
                position: 'absolute',
                left: '6px',
                top: '-24px',
                backgroundColor: 'white',
                padding: '0 4px',
                fontSize: '80%',
                fontWeight: '400',
                fontFamily: 'Poppins',
                color: '#000000',
              }}
            >
              {selectProps.placeholder}
            </label>
          ) : null}
          {children}
        </div>
      </components.Control>
    );
  };
  const responsives = {
    // 0: {
    //     items: 2
    // },
    // 767: {
    //     items: 2
    // },
    // 1024: {
    //     items: 5,
    //     itemsFit: 'contain',
    //     disableButtonsControls: 'true',
    //     infinite: 'true'
    // },
    0: {
      items: 1.7
    },
    344: {
      items: 1.3
    },
    360: {
      items: 1.4
    },
    375: {
      items: 1.5
    },
    390: {
      items: 1.5
    },
    412: {
      items: 1.6
    },
    414: {
      items: 1.6
    },
    430: {
      items: 1.7
    },
    540: {
      items: 2.2,
    },
    576: {
      items: 3
    },
    768: {
      items: 3,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    820: {
      items: 2.0
    },
    1024: {
      items: 3,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    1200: {
      items: 5,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
  };


  const getBoostScoreReport = (index = 0, param) => {

    const { openFromProd, demoClientToken } = props; // Destructure props for clarity
    const token = demoClientToken || null; // Simplify null assignment

    const handleResponse = (resp) => {
      flushSync(() => {
        getBankList(resp.accounts);
        const resAccount = resp.accounts[index];
        setBoostScoreReportData(resp);
        setAnalysisReportData(resAccount.analysis);
        setNewBankname(
          `${resAccount?.analysis.accountDetail.bankName} Bank | ${resAccount?.analysis.accountDetail.accountType} Account - ${resAccount?.analysis.accountDetail.accountNumber.slice(-4)}`
        );
        setAcHolderName(resAccount.analysis.accountDetail.accountHolderName);
        setTransactionPeriod(
          `${resAccount.analysis.accountDetail.transactionStartDate} - ${resAccount.analysis.accountDetail.transactionEndDate}`
        );
        setIncomeVsExpenseData(resAccount.analysis.incomeVsExpenseAnalysis);
        setTopSpendingCategoryData(resAccount.analysis.categoryAnalysis);
        setTopMerchantData(resAccount.analysis.merchantAnalysis);
        setTopCardUtilizationData(resAccount.analysis.creditCardUtilization);
        setMonthlyObligationData(resAccount.analysis.obligation);
        setInvestmentData(resAccount.analysis.investment);
        insightsAnalysisReports(resAccount.analysis);
        setBankIndexCurrent(index);
        setLoader(false);
        if (resAccount.score > 0) {
          setScore(resAccount.score);
        }
      });


      window.dispatchEvent(new Event('resize'));
    };

    const fetchBoostScoreReport = props.openFromProd ? boostScoreReportProdBID : boostScoreReportBID;

    setLoader(true);
    fetchBoostScoreReport(param, token)
      .then(handleResponse)
      .catch(() => {
        setLoader(false);
      });
  };


  const getBankList = (bankLists) => {

    if (bankLists) {
      for (let index = 0; index < bankLists.length; index++) {
        const bankData = {
          bankName: bankLists[index].bankName,
          accountSubType: bankLists[index].accountSubType,
          accountType: bankLists[index].accountType,
          accountNumber: bankLists[index].accountNumber.slice(-4)
        };
        bankNameList.push(bankData)
        // bankNameList.push(bankLists[index].analysis.accountDetail.bankName + " | " + bankLists[index].analysis.accountDetail.accountType + ' Account - ' + bankLists[index].analysis.accountDetail.accountNumber.slice(-4));
      }
    }

    setBankList(bankNameList)

  }



  const closeScoreDetails = () => {
    setScoreDitailsDialog(false)
    setDialogHeight('auto')
  }



  return (
    <>
      {loader && <Loader />}
      <div className='boost-score-heding-page'>


        {bankList.length !== 0 && <div className='container-lg container-fluid my-sm-3 pt-2 ps-lg-3'>
          <Select
            styles={customStyles}

            components={{ Control }}
            value={{ value: bankList[bankIndexCurrent], label: bankList[bankIndexCurrent] }}
            defaultValue={{ value: bankList[bankIndexCurrent], label: bankList[bankIndexCurrent] }}
            placeholder='Select Bank Account'
            isSearchable={false}
            options={bankList.map((bankNames, index) => ({
              value: bankNames,
              label: bankNames,
            }))}
            onChange={(index) => {
              bankIndex = bankList.indexOf(index.value);
              setBankIndexCurrent(bankIndex)
              let param = {
                "businessUserId": props.businessUserId
              }
              getBoostScoreReport(bankIndex, param);
            }}
            formatOptionLabel={bankNames => (
              <div className='d-flex flex-row p-0 m-0  align-items-center'>
                <div className="bank-option pt-1">
                  <img src={GetBankLogo(bankNames.value.bankName)} alt="dropdown-logo" className='bank-dropdown-logo' />
                </div>
                <div className='p-0 m-0 ps-2 pt-1'>
                  <p className='p-0 m-0 bank-name'>{bankNames.value.bankName}</p>
                  <div className='d-flex flex-row p-0 m-0'>
                    <p className='p-0 m-0 text-capitalize bank-acc-name'>{bankNames.value.accountSubType}</p>
                    {bankNames.value.bankName && <p className='p-0 m-0 px-1 bank-acc-name'>|</p>}
                    <p className="p-0 m-0 text-capitalize bank-acc-name">
                      {bankNames.value.accountType === '' || bankNames.value.accountType === undefined
                        ? `${'XXXX'} - ${bankNames.value.accountNumber}`
                        : `${bankNames.value.accountType} - ${bankNames.value.accountNumber}`}
                    </p>
                  </div>
                </div>
              </div>
            )}
          />
        </div>}

        {bankList.length !== 0 && <div className='container-lg container-fluid mt-4 my-sm-3 '>

          <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
            <div className='col-5'>
              <div className='ps-sm-2'>

                <p className='credit-bureau-text mb-2'>A/C Holder Name: <span className='bold-txt'>{acHolderName}</span></p>
                <p className='credit-bureau-text mb-1'>Transaction Period: <span className='bold-txt'> {transactionPeriod}</span></p>
              </div>
            </div>
            <div className='col-7' id='boostscore'>
              <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                <div className='text-end text-sm-end me-sm-2'>
                  <p className='mb-0 userscore-textes'>Your <span className='boost-text'>Boost</span> score</p>
                  <h1 className={score && score !== null && score > 0 ? 'credit-score' : 'credit-score0'} >
                    {(score && score !== null) ? (score && score !== null && score !== null && Number(score) >= 0) ? score : '0' : '---'}
                  </h1>
                </div>
                <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                  <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                    {(score >= 780 && score <= 900) &&
                      <div className='excellect-box score-box'>
                        <p className='mb-0 score-box-text'>Excellent</p>
                      </div>}
                    {(score >= 706 && score <= 779) &&
                      <div className='goods-box score-box'>
                        <p className='mb-0 score-box-text'>Good</p>
                      </div>}
                    {(score >= 631 && score <= 705) &&
                      <div className='average-box score-box'>
                        <p className='mb-0 score-box-text'>Average</p>
                      </div>}
                    {(score > 0 && score <= 630) &&
                      <div className='poor-box score-box'>
                        <p className='mb-0 score-box-text'>Poor</p>
                      </div>}
                    {(score == undefined || score == null || score == 0) &&
                      <div className='score-not-show-box score-box mx-2'>
                        <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                      </div>}
                  </div>
                  <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1"
                    onClick={() => { openScoreDetails() }}
                  ></i></div>
                </div>
              </div>
            </div>

            <div className='crdit-score-dailog'>
              {scoreDitailsDialog && (
                <ReactDialogBox
                  closeBox={closeScoreDetails}
                  headerBackgroundColor='white'
                  headerTextColor='black'
                  closeButtonColor='black'
                  bodyBackgroundColor='white'
                  bodyTextColor='black'
                  headerText="Boost Score Range"
                  bodyHeight={dialogHeight}
                  borderRadius='8px'
                  headerHeight='65px'
                >
                  <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                </ReactDialogBox>)}
            </div>
          </div>
        </div>}


        <section>
          <ErrorMessage message={message} state={state} />
        </section>

        {(bankList.length === 0 && loader === false) && <div className="col-12 text-center">
          <img src={NoFoundDocs} className="no-found-doc-img" />
          <p className="p-0 m-0 doc-desc fw-bold pt-4">
            Looks like there are not available bank transactions for this user.
          </p>
        </div>}

        {analysisReportData && analysisReportData.ratio &&
          <section id='insights'>
            <div className='container-lg container-fluid my-3'>
              <div className='insights-main-box p-0 m-0'>
                <div className='row g-2'>
                  <div className='col-12 p-0'>
                    <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>INSIGHTS</p>

                  </div>
                </div>
                <div className='dividers w-100'></div>
                <div className='row g-2'>
                  <div className='col-12 p-0 m-0'>
                    <p className='insights-sub-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Check out the factors affecting your BOOST score!</p>
                  </div>
                  <div className='col-12 parent-insight-bt'>


                    <AliceCarousel
                      items={insightsItem}
                      responsive={responsives}>
                    </AliceCarousel>

                    <div className='insightdialog'>
                      {insightsDialog && (
                        <ReactDialogBox
                          closeBox={closeinsght}
                          headerBackgroundColor='white'
                          headerTextColor='black'
                          closeButtonColor='black'
                          bodyBackgroundColor='white'
                          bodyTextColor='black'
                          bodyHeight={dialogHeight}
                          borderRadius='8px'
                        >
                          <BoostInsights closeDialog={closeinsght}
                            setDialogHeight={setDialogHeight}
                            selectedAnalysisData={selectedAnalysisData}
                            setAnalysisReportData={analysisReportData}
                            setIncomeVsExpenseData={incomeVsExpenseData}
                            setTopSpendingCategoryData={topSpendingCategoryData}
                            setTopMerchantData={topMerchantData}
                            setTopCardUtilizationData={topCardUtilizationData}
                            setMonthlyObligationData={topMonthlyObligationData}
                            setInvestmentData={investmentData}
                          />
                        </ReactDialogBox>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section >}

        {analysisReportData && analysisReportData.sectionData && <section id='summatyaccount' className='accountdetailchart'>
          <section id='analysis'>
            <div className='container-lg container-fluid my-3'>
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    <div className='w-100'>
                      <div>
                        <div className='d-flex justify-content-between'>
                          <p className="acc-info-text" >Boost score analysis</p>
                          <span className='text-end me-2 view-text-all'>View All</span>
                        </div>
                      </div>
                      <section>
                        <div className='border-top'>
                          <div className='my-2'>
                            <div className='row align-items-center g-3'>
                              <div className='col-lg-2'>
                                <p className='account-summary-text'>Transaction Summary</p>
                                {/* <div className='dividers w-100'></div> */}
                              </div>
                              <div className='col-lg-10 row g-2'>
                                {analysisReportData && analysisReportData.sectionData && analysisReportData.sectionData.amountData.map((amtData, inx) => {

                                  return (
                                    <div className='col-6 col-md-3' key={inx}>
                                      <div className='acsummary-box'>
                                        {(amtData && amtData.title === "Total Income" ? <div className='balance-left-border-box'></div> : amtData.title === "Investments" ? <div className='payments-left-border-box'></div> : amtData.title === "Expenses" ? <div className='open-left-border-box'></div> : amtData.title === "Obligations" ? <div className='closed-left-border-box'></div> : "")}
                                        <div className='text-center mx-auto'>
                                          <p className='summary-sub-text'>{(amtData && amtData?.title !== null) ? (amtData?.title && amtData?.title != null) ? amtData?.title : '-' : '-'}</p>
                                          <h3 className='summary-numbers'>{(amtData && amtData?.amount !== null) ? (amtData?.amount && amtData?.amount != null) ? "₹ " + amtData?.amount.toLocaleString() : '-' : '-'}</h3>
                                        </div>
                                      </div>
                                    </div>)
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </h2>

                  <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                    <div className="accordion-body">

                      {incomeVsExpenseData && incomeVsExpenseData.data != null &&
                        <TranscationAnalysis incomeVsExpenseData={incomeVsExpenseData} />
                      }
                      <div className='row mt-3 spending-category'>
                        {topSpendingCategoryData && topSpendingCategoryData.data != null &&
                          <TopCategory topSpendingCategoryData={topSpendingCategoryData} />
                        }
                        {topMerchantData && topMerchantData.data != null &&
                          <TopMerchant topMerchantData={topMerchantData} />
                        }
                      </div>
                      <div className='row mt-3 spending-category'>
                        {topCardUtilizationData && topCardUtilizationData.data != null &&
                          <CreditCard topCardUtilizationData={topCardUtilizationData} />
                        }
                        {investmentData && investmentData.data != null &&
                          <TopInvestment investmentData={investmentData} />
                        }
                      </div>
                      {topMonthlyObligationData && topMonthlyObligationData.data != null &&
                        <MonthlyObligation topMonthlyObligationData={topMonthlyObligationData} />
                      }


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>}


      </div>

    </>
  )
}

export default BoostTabComponent