import React from 'react';

const SelectableInfoBox = ({ title, description, isSelected, img, onClick }) => {
    return (
        <div
            className="info-details-box row p-0 m-0 px-2 py-2 me-3"
            onClick={onClick}
            style={{ cursor: 'pointer', border: isSelected ? '1.5px solid #4243F1' : '1px solid #d0d5dd' }}
        >
            <div className='p-0 m-0 d-inline-flex align-items-center'>
                <img style={{ width: 'auto', height: '24px' }} src={img} alt='img' />
                <p
                    className='p-0 m-0 my-2 ps-1'
                    style={{ fontWeight: 'bold', }}
                >
                    {title}
                </p>
            </div>
            <p
                className='p-0 m-0 ps-1'
                style={{ fontWeight: 'light', }}
            >
                {description}
            </p>
        </div>
    );
};

export default SelectableInfoBox;
