import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Tooltip from '../../../../Component/tool-tip';
const InvestmentLogo = require("../../../../../images/assets/top_investment.png");

const TopInvestment = (props) => {

  let invCategorieName = [];
  let invTotalAmount = [];
  let invSpendingPer = [];
  let investmentData = props.investmentData ? props.investmentData : null

  useEffect(() => {
    {
      investmentData && investmentData.categoryData && investmentData.categoryData.forEach((investment) => {
        invCategorieName.push(investment.categoryName)
        invSpendingPer.push(parseInt(investment.spendingPercentage))
        invTotalAmount.push(investment.totalAmount)
      })
    }
  })

  const investmentChart = {

    series: invSpendingPer,
    options: {
      chart: {
        width: '55%',
        type: 'pie',
      },
      labels: invCategorieName,
      theme: {
        monochrome: {
          enabled: true
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          }
        }
      },
      fill: {
        colors: ['#334BF3', '#8593F8', '#ADB7FA', '#D6DBFD', '#dddff0']
      },
      tooltip: {
        enabled: true,
        intersect: true,
        shared: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const categoryName = w.globals.labels[seriesIndex].toLowerCase()
          const categoryAmount = invTotalAmount[seriesIndex]
          return '<div class="arrow_box">' +
            '<p class="arrow-text">' + categoryName + '</p>' +
            '<p class="arrow-amt">' + '₹ ' + categoryAmount.toLocaleString() + '</p>' +
            '</div>'

        }
      },
      dataLabels: {
        formatter(val, opts) {
          const dt = opts.w.globals.series[opts.seriesIndex]
          return [dt + "%"]
        }
      },
      legend: {
        show: false
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: 250
            },
          }
        }
      ]
    },
  };

  return (
    <>
      {investmentData && investmentData.amountInformation != null &&
        <div className='col-6'>
          <div className='account-details-box'>
            <div className="accordion pie-chart">
              <p className="acc-info-text mb-0">Top Investments
                <Tooltip content="Categories in which you have been investing">
                  <i className="bi bi-info-circle boost-info-icon me-1"></i>
                </Tooltip>
              </p>

              <p className="subtitle-heading-chart">{investmentData && investmentData.amountInformation != null && investmentData?.amountInformation?.percentageText != null ? '(' + investmentData.amountInformation.percentageText + ')' : ''}</p>
              <div className='dividers w-100'></div>

              <div className="top-chart-logo">
                <img src={InvestmentLogo} className='img-fluid' />
                <div className="top-chart-text">
                  <p className="acc-info-text mb-0">{investmentData && investmentData.amountInformation != null && investmentData?.amountInformation?.name != null ? investmentData.amountInformation.name + ":" + " ₹" + investmentData.amountInformation.amount : ''}</p>
                  <p className="subtitle-heading-chart">{investmentData && investmentData.amountInformation != null && investmentData?.amountInformation?.label != null ? investmentData.amountInformation.label : ''}</p>
                </div>
              </div>
              <ReactApexChart options={investmentChart.options} series={investmentChart.series} type="pie" height={335} />
            </div>
          </div>
        </div>}
    </>
  )
}

export default TopInvestment