import React from 'react';

function Tab({ index, activeIndex, text, onClick }) {
    const isActive = index === activeIndex;

    return (
        <div className={isActive ? 'tab-active' : 'tab-inactive'} onClick={() => onClick(index)}>
            <p className={isActive ? 'tab-text-active two-line-text' : 'tab-text-inactive two-line-text'}>{text}</p>
        </div>
    );
}

export default function Tabs({ activeIndex, onTabChange, isShowScurb, isShowCredit, isShowBank, isShowGST }) {
    return (
        <section className='d-flex flex-row align-items-center justify-content-center mt-0 '>
            <div className='tabs-parent'>
                {isShowScurb && <Tab index={0} activeIndex={activeIndex} text="Scrub Data" onClick={onTabChange} />}
                {isShowScurb && <div className='ms-2 me-2'></div>}
                {isShowCredit && <Tab index={1} activeIndex={activeIndex} text="Credit Report Data" onClick={onTabChange} />}
                {isShowCredit && <div className='ms-2 me-2'></div>}
                {isShowBank && <Tab index={2} activeIndex={activeIndex} text="Bank Transactions Data " onClick={onTabChange} />}
                {isShowBank && <div className='ms-2 me-2'></div>}
                {isShowGST && <Tab index={3} activeIndex={activeIndex} text="GST information " onClick={onTabChange} />}
            </div>
        </section>
    );
}