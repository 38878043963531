import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "./filter-scrub-component.css";



const FilterScrubComponent = ({
    filterText,
    onFilter,
    onClear,
    selectedFilter,
    onSelectFilter,
    filterEnableDisable,
    onSearch
}) => {


    const filters = ["Name", "Phone Number", "Credit Report ID"];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);



    // Toggle dropdown visibility
    const toggleDropdown = () => {
        if (filterEnableDisable.isSearchEnable) {
            setIsDropdownOpen((prev) => !prev);
        }
    };

    // Close dropdown when clicking outside
    useEffect(() => {

        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    return (
        <div className="search-container-filter-scrub p-0 m-0">

            <input
                id="search"
                type="text"
                disabled={!filterEnableDisable.isSearchEnable}
                placeholder="Search"
                value={filterText}
                onChange={onFilter}
                className="input-field"
            />
            {/* <button className="clear-button p-0 m-0" onClick={onClear}>
                ✕
            </button> */}

            <div className="clear-button p-0 m-0 pe-2">
                <div ref={dropdownRef}>
                    <div

                        className="dropdown-btn" type="button" onClick={toggleDropdown}>
                        {selectedFilter} <i className="px-1 bi bi-chevron-down"></i>
                    </div>
                    {isDropdownOpen && (
                        <ul className="dropdown-ul-list"

                        >
                            {filters.map((filter) => (
                                <li
                                    key={filter}
                                    onClick={() => {
                                        toggleDropdown();
                                        onSelectFilter(filter);
                                    }}
                                    className={`p-0 m-0 dropdown-item-li ${selectedFilter === filter ? "selected" : ""
                                        }`}
                                // style={{
                                //     padding: "12px 8px",
                                //     cursor: "pointer",
                                //     fontWeight: selectedFilter === filter ? "bold" : "normal",
                                // }}
                                >
                                    {filter}
                                </li>
                            ))}
                        </ul>
                    )}

                </div>
            </div>

            <button className="search-icon p-0 m-0" onClick={onSearch}>
                <span>
                    <i className="bi bi-search p-0 m-0"></i>
                </span>
            </button>


        </div>
    );
};


export default FilterScrubComponent;

